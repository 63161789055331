<template>
  <div class="addInstallationEvent">
    <form>
      <div class="form-group">
        <h4 class="m-0 mt-3">
          Installation Event
        </h4>
        <hr class="m-0 mb-3">
        <label>Name</label>
        <input
          v-model="installationEvent.name"
          v-focus
          :class="['form-control']"
          type="text"
        >
        <hr class="m-0 mb-3">
        <label>{{ $t('eventType') }}</label>
        <LoadingPlaceholder v-if="loading" />
        <ComboBox
          v-else
          v-model="installationEvent.eventType"
          :data-source="eventTypes"
          :placeholder="'Select a event type...'"
          class="form-control"
        />
        <div class="m-0 mb-3 mt-3" />
        <div class="form-check form-check-inline mb-3 mr-5">
          <input
            id="executeAction"
            v-model="installationEvent.executeAction"
            class="form-check-input"
            type="checkbox"
          >
          <label
            class="form-check-label"
            for="executeAction"
          >Automatically disable Monitoring during Event</label>
        </div>
        <div
          v-if="hasMaintenanceSetting"
          class="form-check form-check-inline mb-3 mr-5"
        >
          <input
            id="switchToMaintenanceMode"
            v-model="installationEvent.switchToMaintenanceMode"
            class="form-check-input"
            type="checkbox"
          >
          <label
            class="form-check-label"
            for="switchToMaintenanceMode"
          >Automatically activate maintenance mode / page during event</label>
        </div>
        <label>{{ $t('startDate') }}</label>
        <date-picker
          v-model="installationEvent.startDate"
          :editable="true"
          :clearable="false"
          type="datetime"
          lang="en"
          format="DD.MM.YYYY HH:mm"
          value-type="date"
          :first-day-of-week="1"
          class="ml-3"
        />
        <div class="m-0 mb-3" />
        <label>{{ $t('endDate') }}</label>
        <date-picker
          v-model="installationEvent.endDate"
          :editable="true"
          :clearable="false"
          type="datetime"
          lang="en"
          format="DD.MM.YYYY HH:mm"
          value-type="date"
          :first-day-of-week="1"
          class="ml-4"
        />
        <div class="m-0 mb-3" />
        <label>{{ $t('description') }}</label>
        <textarea
          v-model="installationEvent.description"
          class="form-control m-input m-input--air"
          name="Description"
          rows="5"
          placeholder="Please enter your description"
        />
      </div>
      <button
        class="btn btn-primary float-right"
        :disabled="!installationEvent.eventType || !installationEvent.startDate || !installationEvent.endDate"
        @click.prevent="editInstallationEvent()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="edit"
        />{{ $t('edit') }}
      </button>
      <div class="clearfix" />
    </form>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import { ComboBox } from '@progress/kendo-dropdowns-vue-wrapper';

export default {
  name: "AddInstallationEvent",
  components: {
    ComboBox,
    DatePicker
  },
  props: {
    event: {
      type: Object,
      required: true
    },
    serviceId: {
      type: String,
      default: null
    },
    hasMaintenanceSetting: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      eventTypes: [],
      installationEvent: Object.assign({}, this.event) 
    }
  },
  created () {
    this.getEventTypes();
  },
  methods: {
    async getEventTypes () {
      this.loading = true;
      await this.axios.get('/Installation/GetInstallationEventTypes')
        .then((response) => {
          if (response && response.status && response.status == 200) {
            this.eventTypes = response.data;
          }
        })
        .finally(() => {
          this.loading = false; 
        });
    },
    async editInstallationEvent () {
      await this.axios.put(`/Freshdesk/EditInstallationEvent?serviceId=${this.serviceId}`, this.installationEvent)
        .then((res) => {
          if(res) {
            this.$snotify.success(this.$t('installationEvent.updatedSuccessfully'));
            this.$emit("reloadEvents");
          }
        })
        .catch((error) => {
          this.$snotify.error(error.message);
        });
    }
  }
}
</script>

<style scoped>
.addInstallationEvent .progress {
  display: flex;
  flex: 1 1 auto;
  width: 1%;
  height: 20px;
  font-size: 1rem;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>